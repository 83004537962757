.button-container {
  // width: 372px;
  // height: 83.01px;
  // left: 570px;
  // top: 649px;
  background-color: rgba($color: #000000, $alpha: 0);
  border: 2px solid cyan;
  border-radius: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: cyan;
  cursor: pointer;

  &:hover {
    border: cyan;
    background-color: cyan;
    color: black;
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: white;
  }

  &.splash-enter {
    background: #9b0363;
  }
}
