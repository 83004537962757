.date-display-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .date-item-container {
    padding: 0.2rem; // Reduced padding for smaller displays
    background: rgba(0, 20, 50, 0.8); // Dark translucent
    border-radius: 50%;
    box-shadow: 0 0 10px #8ed1fc; // Glowing effect
  }

  .date-item-text {
    background-color: transparent;
    font-size: 2rem; // Scaled down for mobile
    color: #8ed1fc;
    font-weight: bold;
    text-shadow: 0 0 20px #8ed1fc, 0 0 40px #8ed1fc;
  }

  .date-time-text {
    background-color: transparent;
    margin-top: 0.5rem;
    font-size: 0.8rem; // Reduced label font size
    color: #d4efff; // Lighter cotton candy blue for labels
    text-shadow: 0 0 10px #8ed1fc;
  }
}

/* Media Query for very small screens */
@media (max-width: 480px) {
  .date-item-container {
    padding: 0.6rem; // Smaller padding for compact displays
  }

  .date-item-text {
    font-size: 1.5rem; // Further reduced text size
  }

  .date-time-text {
    font-size: 0.7rem; // Adjusted for smaller labels
  }
}
