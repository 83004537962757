.sign-up-container {
  .sign-up-header {
    font-size: 2rem;
    color: #ff80e1; /* Cotton candy pink */
    text-align: center;
    margin-bottom: 1.5rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Matrix-like text effect */
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  input {
    padding: 1rem;
    border: 1px solid #ff80e1;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
  }

  input:focus {
    outline: none;
    border-color: #87cefa; /* Cotton candy blue */
    box-shadow: 0 0 5px rgba(135, 206, 250, 0.7);
  }

  .buttons-container button {
    background-color: #ff80e1;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }

  .buttons-container button:hover {
    background-color: #ff4d94;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: 90%;
    .sign-up-header {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    form {
      gap: 0.8rem;
    }

    input {
      font-size: 0.9rem;
      padding: 0.8rem;
    }

    .buttons-container button {
      padding: 0.7rem 1.2rem;
      font-size: 0.9rem;
    }
  }
}
