// .navbar {
//   background: linear-gradient(135deg, #ffb6c1, #d1a6ff); // Cotton candy gradient
//   padding: 15px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//   position: relative;
//   height: auto;

//   .custom-link {
//     text-decoration: none; /* Removes the underline */
//     color: inherit; /* Inherits color from parent or sets your desired color */

//     /* Optionally, style it further */
//     font-weight: bold;
//     font-size: 16px;
//   }

//   .custom-link:hover {
//     color: #8cd7f4; /* Optional: Change color on hover */
//   }

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* General Navbar Styles */
/* General Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  // background-color: #282c34;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.navbar-brand {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar-brand .icon {
  width: 50px;
  background-color: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

// .navbar-brand .icon.spinning {
//   // transform: rotate(90deg);
// }

.custom-link {
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: #61dafb;
  transition: text-decoration 0.3s ease;
}

.custom-link:hover {
  color: white;
}

.navbar-menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-grow: 1; /* Ensure navbar-menu takes the remaining space */
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .navbar {
    padding: 0;
  }
  .navbar-menu {
    display: none;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: black;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
  }

  .navbar-brand .icon.spinning {
    transform: rotate(90deg);
  }

  .mobile-menu .nav-link {
    margin: 0.5rem 0;
  }
}

/* Link Styles */
.nav-link {
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #61dafb;
}

/* Modal Styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darker backdrop for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal-content {
  background: #222; /* Darker background for the modal */
  color: #ff79c6; /* Cotton Candy pink text color */
  border-radius: 12px;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5); /* Deeper shadow for depth */
}

.modal-content h3 {
  margin-bottom: 1rem;
  background-color: transparent;
  color: #ff79c6; /* Pink color for headings */
}

.modal-content button {
  background: #ff79c6; /* Cotton Candy pink button */
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-content button:hover {
  background: #ff55a2; /* Slightly darker pink on hover */
}

.modal-content button:last-child {
  background: #ff5555; /* Red color for the last button */
}

.modal-content button:last-child:hover {
  background: #d94444; /* Darker red on hover */
}
