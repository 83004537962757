.splash-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100vh;
  background-color: transparent;
  // background: linear-gradient(135deg, #0a0a0a, #1a1a1a);
  color: #e1e6ff; // cotton candy blue
  font-family: "Orbitron", sans-serif;
  text-align: center;

  .train-list {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    img {
      width: 50px;
      height: 50px;
      border: 2px solid #e1e6ff;
      border-radius: 50%;
      filter: drop-shadow(0 0 8px #e1e6ff);
    }
  }

  .message-container {
    margin-bottom: 30px;

    .welcome-message {
      font-size: 3rem;
      margin: 0 0 10px;
      text-transform: uppercase;
      position: relative;
      color: #e1e6ff;
      text-shadow: 0 0 15px #8b64f7, 0 0 30px #ff99cc;
    }

    .welcome-message.glitch::before,
    .welcome-message.glitch::after {
      content: attr(data-text);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: #8b64f7;
      overflow: hidden;
      clip: rect(0, 900px, 0, 0);
      animation: glitch 2s infinite;
    }

    .subtitle {
      font-size: 1.5rem;
      margin-top: 10px;
      color: #ffccff;
    }
  }

  .info-text {
    margin-top: 20px;
    max-width: 600px;
    font-size: 1rem;
    line-height: 1.5;
    color: #e1e6ff;

    a {
      text-decoration: none;
    }
  }

  button {
    background: linear-gradient(135deg, #8b64f7, #ff99cc);
    color: #1a1a1a;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 10px;

    &:hover {
      background: linear-gradient(135deg, #ff99cc, #8b64f7);
      color: #fff;
      box-shadow: 0 0 10px #ff99cc, 0 0 20px #8b64f7;
    }
  }
}

// .train-list {
//   display: flex;
//   gap: 20px;
//   margin-bottom: 20px;

//   img {
//     width: 50px;
//     height: 50px;
//     border: 2px solid #e1e6ff;
//     border-radius: 50%;
//     filter: drop-shadow(0 0 8px #e1e6ff);
//   }
// }

// .message-container {
//   margin-bottom: 30px;

//   .welcome-message {
//     font-size: 3rem;
//     margin: 0 0 10px;
//     text-transform: uppercase;
//     position: relative;
//     color: #e1e6ff;
//     text-shadow: 0 0 15px #8b64f7, 0 0 30px #ff99cc;
//   }

//   .welcome-message.glitch::before,
//   .welcome-message.glitch::after {
//     content: attr(data-text);
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     color: #8b64f7;
//     overflow: hidden;
//     clip: rect(0, 900px, 0, 0);
//     animation: glitch 2s infinite;
//   }

//   .subtitle {
//     font-size: 1.5rem;
//     margin-top: 10px;
//     color: #ffccff;
//   }
// }

// .info-text {
//   margin-top: 20px;
//   max-width: 600px;
//   font-size: 1rem;
//   line-height: 1.5;
//   color: #e1e6ff;

//   a {
//     text-decoration: none;
//   }
// }

// button {
//   background: linear-gradient(135deg, #8b64f7, #ff99cc);
//   color: #1a1a1a;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   font-size: 1.2rem;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   margin-top: 10px;

//   &:hover {
//     background: linear-gradient(135deg, #ff99cc, #8b64f7);
//     color: #fff;
//     box-shadow: 0 0 10px #ff99cc, 0 0 20px #8b64f7;
//   }
// }

/* Responsive Design */
@media screen and (max-width: 768px) {
  .splash-container {
    padding: 10px;
  }

  .welcome-message {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.2rem;
  }

  .info-text {
    font-size: 0.9rem;
  }

  button {
    font-size: 1rem;
    padding: 8px 16px;
  }
}
