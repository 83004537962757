.product-card-container {
  background: rgba(15, 15, 15, 0.85); // Semi-transparent dark background
  border-radius: 15px;
  border: 2px solid transparent;
  box-shadow: 0 0 15px #00ccff, 0 0 30px #ffccff; // Neon glow effect
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  overflow: hidden;

  &:hover {
    transform: scale(1.05); // Slight zoom effect on hover
    box-shadow: 0 0 20px #00ccff, 0 0 40px #ffccff; // Intensified glow on hover
  }

  .product-img {
    width: 100%;
    height: auto;
    display: block;
    border-bottom: 2px solid #00ccff;
  }

  .footer {
    padding: 1rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      font-size: 1.2rem;
      color: #ffccff; // Cotton candy pink
      text-shadow: 0 0 8px #ffccff, 0 0 20px #ffccff; // Glowing effect
    }

    .price {
      font-size: 1.2rem;
      color: #00ccff; // Cotton candy blue
      text-shadow: 0 0 8px #00ccff, 0 0 20px #00ccff; // Glowing effect
    }

    .stock {
      font-size: 1rem;
      color: #cccccc; // Light gray for stock info
    }
  }

  .btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-out;

    &.btn-success {
      background: #00ccff; // Blue for 'Add to Cart'
      color: #fff;
      text-shadow: 0 0 8px #00ccff, 0 0 20px #00ccff; // Glowing effect on text
    }

    &.btn-danger {
      background: #ff4f81; // Soft red for 'Out of Stock'
      color: #fff;
      text-shadow: 0 0 8px #ff4f81, 0 0 20px #ff4f81; // Glowing effect on text
    }

    &:disabled {
      cursor: not-allowed;
      background: #333; // Darker background for disabled state
      text-shadow: none;
      opacity: 0.7;
    }

    &:hover {
      transform: scale(1.05); // Slight zoom effect on button hover
    }
  }
}
