.sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 10px;
  width: 100%;
  // max-width: 400px;
  margin: 0 auto;

  input {
    padding: 1rem;
    border: 1px solid #ff80e1;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
  }

  input:focus {
    outline: none;
    border-color: #87cefa;
    box-shadow: 0 0 5px rgba(135, 206, 250, 0.7);
  }

  button {
    background-color: #ff80e1;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    width: 100%;
  }

  button:hover {
    background-color: #ff4d94;
  }

  .error {
    color: #ff4d94; /* Cotton candy red for error messages */
    font-size: 0.9rem;
    text-align: center;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: 90%;
    .sign-in-form {
      gap: 0.8rem;
    }

    input {
      font-size: 0.9rem;
      padding: 0.8rem;
    }

    button {
      padding: 0.7rem 1.2rem;
    }

    .error {
      font-size: 0.8rem;
    }
  }
}
